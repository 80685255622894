import { Grid, Link, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { Link as LinkDOM } from "react-router-dom"

export default function NotFound() {
    return (
        <Container maxWidth="sm">
            <Grid
                container
                alignItems="center"
                alignContent="center"
                sx={{
                    height: "100vh"
                }}
                p={2}
                spacing={4}
            >
                <Grid item xs={12}>
                    <Typography
                        align="center"
                        variant="h3"
                        fontWeight={700}
                        gutterBottom
                    >
                        404
                    </Typography>
                    <Typography
                        align="center"
                        variant="h3"
                        fontWeight={700}
                        gutterBottom
                    >
                        Page Not Found
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        align="center"
                        variant="body1"
                        display="block"
                    >
                        <Link component={LinkDOM} to="/">
                            back to home screen
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}