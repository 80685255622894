import React from "react"
import {
    Box,
    Container,
    Paper,
    Grid,
    Typography,
    TextField,
    Button
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { login } from '../../api/auth'
import { LanguageSelectorWrapper } from "../../components/LanguageSelector"
const initialValues = { tenant: '', email: '', password: '' }
const validationSchema = yup.object().shape({
    tenant: yup.string().min(4).max(20)
        .matches(/^[a-zA-Z][a-zA-Z0-9-]+$/, 'Should start with a letter and contains english letters and numbers only')
        .required(),
    email: yup.string().email().required(),
    password: yup.string().min(6).max(20).required(),
})
export default function Login() {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, actions) => {
            login(values.tenant, values.email, values.password)
                .then(() => {
                    enqueueSnackbar(`Logged in successfully`, { variant: 'success', })
                    navigate("/")
                })
                .catch(() => enqueueSnackbar(`Email or password is wrong`, { variant: 'error', }))
                .finally(() => actions.setSubmitting(false))
        },
    })
    return <Container
        maxWidth="sm"
        component={Box}
        mt={5}
        style={{
            height: 'calc(100vh - 40px)',
            display: 'flex',
            flexDirection: 'column'
        }}>
        <Box p={2}>
            <Typography variant="button" align="center" display="block" style={{ fontSize: '30px' }} color="primary ">
                {t('login.HOTEL_ADMIN_DASHBOARD')}
            </Typography>
        </Box>
        <Paper component={Box} p={5}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h5">{t('login.Login')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid component="form" onSubmit={formik.handleSubmit} container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="tenant"
                                name="tenant"
                                label={t("common.Username")}
                                variant='outlined'
                                fullWidth
                                value={formik.values.tenant || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.tenant && Boolean(formik.errors.tenant)}
                                helperText={formik.touched.tenant && formik.errors.tenant}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label={t("common.Email")}
                                variant='outlined'
                                fullWidth
                                value={formik.values.email || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                name="password"
                                label={t("common.Password")}
                                variant='outlined'
                                fullWidth
                                type="password"
                                value={formik.values.password || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Button
                                        onClick={() => formik.resetForm()}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {t('common.Reset')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={formik.isSubmitting}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        {t('common.Submit')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
        <Grid
            py={2}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <Grid item>
                <LanguageSelectorWrapper />
            </Grid>
        </Grid>
    </Container>
}