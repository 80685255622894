import { Link } from 'react-router-dom'
import { NavigateNext } from '@mui/icons-material'
import { Breadcrumbs as BreadcrumbsMUI, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Breadcrumbs = styled(BreadcrumbsMUI)(
    ({ theme }) => ({
        margin: theme.spacing(1),
        fontSize: 18,
        '& a': {
            color: theme.palette.text.primary,
            textDecoration: 'none'
        },
        '& p': {
            fontSize: 18,
        }
    }),
)

export default function ItemTrace(props) {

    const icon = props.icon || <NavigateNext fontSize='small' />
    return (
        <Breadcrumbs separator={icon} aria-label='breadcrumb'>
            {props.items.map(({ text, url }, index) => url
                ? <Link key={index} color='inherit' to={url}>{text}</Link>
                : <Typography key={index} color='inherit'>{text}</Typography>
            )}
        </Breadcrumbs>
    )
}