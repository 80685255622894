import {
    getAuth,
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth'


export const login = (tenant, email, password) => {
    const auth = getAuth()
    auth.tenantId = tenant
    return signInWithEmailAndPassword(auth, email, password)
}
export const logout = () => signOut(getAuth())