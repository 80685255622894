import { Language as LanguageIcon } from "@mui/icons-material"
import { FormControl, Grid, MenuItem, Select } from "@mui/material"
import LanguageCodes from './codes.json'

const LanguageSelector = ({
    items = [],
    value = "",
    onClick
}) => {
    return (
        <FormControl fullWidth>
            <Select
                value={value}
                label=""
                fullWidth
                className="text-sm"
                size="small"
                onChange={onClick}
                renderValue={(value) => (
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        flexWrap="nowrap"
                    >
                        <Grid item container>
                            <LanguageIcon fontSize="small" color="action" />
                        </Grid>
                        <Grid item>
                            {LanguageCodes[value]?.nativeName}
                        </Grid>
                    </Grid>
                )}
            >
                {
                    items.map(item => (
                        <MenuItem
                            key={item.value}
                            value={item.value}
                            className="text-sm"
                        >
                            {LanguageCodes[item.value]?.nativeName}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}
export default LanguageSelector