import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

export default function QueryProvider({ children }) {
    return <QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>
}