import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { MainLayout } from "../../layouts"
import { Table } from "../../components"
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
    Add as AddIcon,
    Check as CheckIcon,
    Clear as ClearIcon,
    CloseRounded as CloseRoundedIcon,
} from '@mui/icons-material'
import { useCreateQRCode, useGetQRCodes } from "../../api/qrCodes"
import {
    Button,
    Divider,
    Drawer,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    MenuItem,
    TextField,
    Typography
} from "@mui/material"
import { Box } from "@mui/system"
import { v4 as uuidv4 } from 'uuid';
import {
    useGetAddresses, 
    useGetRegions,
} from "../../api/addresses"
const validationSchema = yup.object().shape({
    'ref_token': yup.string().required(),
    'notes': yup.string().required(),
    'token_expire_date': yup.string().required(),
    'enrollment_expire_date': yup.string().required(),
    'district': yup.string().required(),
    'door_number': yup.string().required(),
    'building': yup.string().required(),
    'note': yup.string().required(),
    'region': yup.string().required(),
    'street_name': yup.string().required(),
    'longitude': yup.string().required(),
    'latitude': yup.string().required(),

})

function CreateQrCodeDrawer({
    open,
    onClose
}) {
    const { t } = useTranslation()
    const createQRCodeMutation = useCreateQRCode()
    const regionsQuery = useGetRegions()
    const addressesQuery = useGetAddresses()
    const { enqueueSnackbar } = useSnackbar()
    const emptyAddress = {
        'district': '',
        'door_number': '',
        'building': '',
        'note': '',
        'region': '',
        'street_name': '',
        'longitude': '1',
        'latitude': '1',
    }
    const formik = useFormik({
        initialValues: {
            'ref_token': '',
            'notes': '',
            'token_expire_date': '',
            'enrollment_expire_date': '',
            ...emptyAddress
        },
        validationSchema,
        onSubmit: (values, actions) => {
            const payload = { ...values }
            payload['longitude'] = Number(payload['longitude'])
            payload['latitude'] = Number(payload['latitude'])
            createQRCodeMutation
                .mutateAsync(payload)
                .then((res) => {
                    enqueueSnackbar(`qr code added successfully`, { variant: 'success', })
                    handleClose()
                })
                .catch(() => enqueueSnackbar(`failed`, { variant: 'error', }))
                .finally(() => actions.setSubmitting(false))
        }
    })
    const handleClose = () => {
        formik.resetForm()
        onClose()
    }
    return (
        <Drawer
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={open}
            onClose={handleClose}
            anchor='right'
            PaperProps={{
                style: {
                    width: "95vw",
                    maxWidth: "500px"
                }
            }}

        >
            <Box p={2} width="100%" pb={5}>
                <Grid container alignItems="center" justifyContent="space-between"  >
                    <Grid item>
                        <Typography variant='h6' fontWeight={600}>{t("QRCodes.Create_QR_Code")}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <Box pt={3}>
                    <Grid
                        component="form"
                        container
                        flexDirection="column"
                        justifyContent="space-between"
                        onSubmit={formik.handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography>{t("QRCodes.QR_Code_Info")} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="ref-token"
                                name="ref_token"
                                label={t("QRCodes.reference_token")}
                                value={formik.values.ref_token}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.ref_token && Boolean(formik.errors.ref_token)}
                                helperText={formik.touched.ref_token && formik.errors.ref_token}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment size position="end">
                                            <Button
                                                size="small"
                                                onClick={() => formik.setFieldValue('ref_token', uuidv4())}
                                            >
                                                {t("common.Auto_Fill")}
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note"
                                name="notes"
                                label={t("QRCodes.notes")}
                                value={formik.values.notes}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.notes && Boolean(formik.errors.notes)}
                                helperText={formik.touched.notes && formik.errors.notes}
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="token-expire-date"
                                name="token_expire_date"
                                label={t("QRCodes.token_expire_date")}
                                type="datetime-local"
                                value={formik.values.token_expire_date}
                                onChange={event => {
                                    formik.handleChange(event)
                                    if (!formik.touched['enrollment_expire_date'])
                                        formik.setFieldValue('enrollment_expire_date', event.target.value)
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.token_expire_date && Boolean(formik.errors.token_expire_date)}
                                helperText={formik.touched.token_expire_date && formik.errors.token_expire_date}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="enrollment-expire-date"
                                name="enrollment_expire_date"
                                label={t("QRCodes.enrollment_expire_date")}
                                type="datetime-local"
                                value={formik.values.enrollment_expire_date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.enrollment_expire_date && Boolean(formik.errors.enrollment_expire_date)}
                                helperText={formik.touched.enrollment_expire_date && formik.errors.enrollment_expire_date}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{t("QRCodes.Address_Info")} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="address-fill"
                                label={t("QRCodes.Address_Fill")}
                                onChange={(event) => {
                                    const address = event.target.value || emptyAddress
                                    Object.entries(address)
                                        .forEach(([key, value]) => {
                                            if (key in formik.initialValues)
                                                formik.setFieldValue(key, value || "")
                                            formik.setFieldTouched(key, false)
                                        })
                                }}
                                select
                            >
                                <MenuItem value="">
                                    None
                                </MenuItem>
                                {addressesQuery.data?.map((address) => (
                                    <MenuItem key={address.id} value={address}>
                                        {address.region}, {address.district}, {address.street_name}, {address.building}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="region"
                                name="region"
                                label={t("QRCodes.region")}
                                value={formik.values.region}
                                onChange={(event) => {
                                    formik.handleChange(event)
                                    formik.setFieldValue('district', '')
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.region && Boolean(formik.errors.region)}
                                helperText={formik.touched.region && formik.errors.region}
                                select
                            >
                                {regionsQuery.data?.map(location => (
                                    <MenuItem key={location.region} value={location.region}>
                                        {location.region}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="district"
                                name="district"
                                label={t("QRCodes.district")}
                                value={formik.values.district}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.district && Boolean(formik.errors.district)}
                                helperText={formik.touched.district && formik.errors.district}
                                disabled={!formik.values.region}
                                select
                            >
                                {regionsQuery.data
                                    ?.find(location => location.region === formik.values.region)
                                    ?.districts
                                    ?.map(district => (
                                        <MenuItem key={district} value={district}>
                                            {district}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="street_name"
                                name="street_name"
                                label={t("QRCodes.street_name")}
                                value={formik.values.street_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.street_name && Boolean(formik.errors.street_name)}
                                helperText={formik.touched.street_name && formik.errors.street_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="building"
                                name="building"
                                label={t("QRCodes.building")}
                                value={formik.values.building}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.building && Boolean(formik.errors.building)}
                                helperText={formik.touched.building && formik.errors.building}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="door_number"
                                name="door_number"
                                label={t("QRCodes.door_number")}
                                value={formik.values.door_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.door_number && Boolean(formik.errors.door_number)}
                                helperText={formik.touched.door_number && formik.errors.door_number}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note"
                                name="note"
                                label={t("QRCodes.note")}
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={() => formik.resetForm()}
                                    >
                                        {t("common.Reset")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        {t("common.Submit")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Drawer >
    )
}
export default function QRCodes() {
    const { t } = useTranslation()
    const qrCodesQuery = useGetQRCodes()
    const navigate = useNavigate()
    const [qrCodeDrawer, setQrCodeDrawer] = React.useState(false)
    return (
        <MainLayout
            title={""}
            active="qr-codes"
        >
            <Table
                title={t("QRCodes.QR_Codes")}
                isLoading={qrCodesQuery.isFetching}
                options={{
                    filtering: true,
                    search: true
                }}
                columns={[
                    {
                        title: t("QRCodes.reference_token"),
                        field: 'id'
                    },
                    {
                        title: t("QRCodes.notes"),
                        field: 'notes'
                    },
                    {
                        title: t("QRCodes.used"),
                        field: 'used',
                        lookup: {
                            [true]: t('common.Yes'),
                            [false]: t('common.No'),
                        },
                        render: rowsData => rowsData.used ? <CheckIcon color="success" /> : <ClearIcon color="error" />,
                    },
                    {
                        title: t("QRCodes.token_expire_date"),
                        field: 'token_expire_date',
                        type: 'datetime'
                    },
                    {
                        title: t("QRCodes.enrollment_expire_date"),
                        field: 'enrollment_expire_date',
                        type: 'datetime'
                    },
                    {
                        title: t("QRCodes.enrollment_id"),
                        field: 'enrollment',
                        render: rowData => rowData.enrollment?.id
                            ? <Link onClick={event => {
                                event.stopPropagation()
                                navigate(`/enrollments/${rowData.enrollment.id}`)
                            }}>
                                {rowData.enrollment.id}
                            </Link>
                            : ""
                    },
                    {
                        title: t("QRCodes.enroller_name"),
                        field: 'user',
                        render: rowData => rowData.user
                            ? <Link onClick={event => {
                                event.stopPropagation()
                                navigate(`/users/${rowData.enrollment.user_id}`)
                            }}>
                                {rowData.user.name}
                            </Link>
                            : ""
                    },
                ]}
                actions={[
                    {
                        icon: AddIcon,
                        tooltip: t('QRCodes.Create_QR_Code'),
                        isFreeAction: true,
                        onClick: (event) => setQrCodeDrawer(true)
                    }
                ]}
                data={qrCodesQuery.data}
                onRowClick={(_, rowData) => navigate(`/qr-codes/${rowData.id}`)}
            />
            <CreateQrCodeDrawer
                open={qrCodeDrawer}
                onClose={() => setQrCodeDrawer(false)}
            />
        </MainLayout>
    )
}