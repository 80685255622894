import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { MainLayout } from "../../layouts"
import { Table } from "../../components"
import { useDeleteUser, useGetUsers } from "../../api/users"
import {
    Check as CheckIcon,
    Clear as ClearIcon
} from '@mui/icons-material'
import { MoreMenu } from "../../components/Table"
import { useConfirm } from 'material-ui-confirm'
// import moment from "moment"
export default function Users() {
    const { t } = useTranslation()
    const usersQuery = useGetUsers()
    const deleteUserMutation = useDeleteUser()
    const navigate = useNavigate()
    const confirm = useConfirm()
    const { enqueueSnackbar } = useSnackbar()
    return (
        <MainLayout
            title={""}
            active="renters"
        >
            <Table
                title={t('users.Renters')}
                isLoading={usersQuery.isFetching}
                options={{
                    filtering: true,
                    search: true
                }}
                columns={[
                    {
                        title: t("users.name"),
                        field: 'name'
                    },
                    {
                        title: t("users.notification_email"),
                        field: 'notification_email'
                    },
                    {
                        title: t("users.gender"),
                        field: 'gender',
                        lookup: {
                            Male: t('common.Male'),
                            Female: t('common.Female')
                        },
                    },
                    {
                        title: t("users.enrolled"),
                        field: 'enrollment',
                        lookup: {
                            [true]: t('common.Yes'),
                            [false]: t('common.No')
                        },
                        render: rowsData => rowsData.enrollment ? <CheckIcon color="success" /> : <ClearIcon color="error" />,
                    },
                    {
                        title: t("users.dob"),
                        field: 'dob'
                    },
                    {
                        title: "",
                        field: 'control', width: "10px", filtering: false,
                        render: rowData => (
                            <MoreMenu
                                items={[
                                    {
                                        disabled: !rowData.enrollment,
                                        label: t('users.unenroll'),
                                        onClick: () => {
                                            confirm({
                                                title: t('common.are_you_sure'),
                                                description: t('common.unenroll_description_modal'),
                                                confirmationText: t('common.ok'),
                                                cancellationText: t('common.cancel'),
                                            })
                                                .then(() => {
                                                    deleteUserMutation
                                                        .mutateAsync(rowData.id)
                                                        .then(() => enqueueSnackbar(`user unenrolled successfully`, { variant: 'success', }))
                                                        .catch(() => enqueueSnackbar(`Failed to unroll user `, { variant: 'error', }))
                                                })
                                                .catch(() => { })
                                        }
                                    },
                                ]}
                            />
                        )
                    }
                ]}
                data={usersQuery.data}
                onRowClick={(_, rowData) => navigate(`/users/${rowData.id}`)}
            />
        </MainLayout>
    )
}