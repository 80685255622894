import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './modules/App'
import {
    BrowserRouter
} from "react-router-dom"
import { ConfirmProvider } from 'material-ui-confirm'
import { ErrorBoundary, ScrollToTop } from './modules/components'
import { QueryProvider, ThemeProvider, } from './modules/providers'
import './modules/providers/LanguageProvider'
import { SnackbarProvider } from 'notistack'
import './modules/config/firebase'
import 'moment/locale/ar'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ErrorBoundary>
        <BrowserRouter>
            <QueryProvider>
                <React.Suspense fallback="">
                    <ThemeProvider>
                        <SnackbarProvider
                            dense
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <ConfirmProvider
                                defaultOptions={{
                                    cancellationButtonProps: { color: 'error', variant: 'text' },
                                    confirmationButtonProps: { color: 'primary', variant: 'contained' },
                                }}
                            >
                                <ScrollToTop />
                                <App />
                            </ConfirmProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </React.Suspense>
            </QueryProvider>
        </BrowserRouter>
    </ErrorBoundary>
)
