import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { MainLayout } from "../../layouts"
import { Breadcrumbs, Table } from "../../components"
import {
    Logout as LogoutIcon,
    Visibility as VisibilityIcon
} from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import {
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Typography
} from "@mui/material"
import { Box } from "@mui/system"
import {
    useDeleteEnrollment,
    useGetEnrollment,
    useGetEnrollmentOrders,
    useGetEnrollmentOrdersCost
} from "../../api/enrollments"
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import { useNavigate } from 'react-router-dom'

function EnrollmentCost({ enrollment_id }) {
    const { t } = useTranslation()
    const costQuery = useGetEnrollmentOrdersCost(enrollment_id)
    return (
        <Grid container justifyContent="center">
            <Grid item xs="auto">{
                costQuery.isLoading
                    ? <Skeleton variant="rectangular" width={250} height={150} />
                    : costQuery.isError
                        ? "error"
                        :
                        <Paper>
                            <Box py={5} px={4}>
                                <Grid container item xs={12} spacing={3} justifyContent="center">
                                    <Grid item>
                                        <PriceChangeIcon color="success" sx={{ fontSize: '50px' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" >{t("enrollments.Total_Cost")}  </Typography><Typography variant="h5" > {costQuery.data} {t("enrollments.EGP")}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
            }
            </Grid>
        </Grid>
    )
}

function EnrollmentCard({ enrollment_id }) {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const confirm = useConfirm()
    const deleteEnrollmentMutation = useDeleteEnrollment()
    const enrollmentQuery = useGetEnrollment(enrollment_id)
    const navigate = useNavigate()
    return (
        enrollmentQuery.isLoading
            ? <Skeleton variant="rectangular" width="100%" height={200} />
            : enrollmentQuery.isError
                ? "error"
                : <Paper>
                    <Box py={3} px={2}>
                        <Grid container item xs={12} spacing={3} justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="button" display="inline" style={{ fontSize: '16px' }}>{t("enrollments.Renter_Info")}</Typography>
                                        <IconButton
                                            size="small"
                                            onClick={() => navigate(`/users/${enrollmentQuery.data.user_id}`)}
                                        >
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><Box pr={1}><Typography variant="button" >{t("users.name")}:</Typography></Box></td>
                                                    <td><Typography variant="overline">{enrollmentQuery.data.user?.name}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td><Box pr={1}><Typography variant="button" >{t("users.dob")}:</Typography></Box></td>
                                                    <td><Typography variant="overline">{enrollmentQuery.data.user?.dob}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td><Box pr={1}><Typography variant="button" >{t("users.gender")}:</Typography></Box></td>
                                                    <td><Typography variant="overline">{enrollmentQuery.data.user?.gender}</Typography></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="button" display="block" style={{ fontSize: '16px' }}>{t("enrollments.Enrollment_Info")}</Typography>
                                        <tbody>
                                            <tr>
                                                <td><Box pr={1}><Typography variant="button" >{t("enrollments.Status")}:</Typography></Box></td>
                                                <td>
                                                    <Typography variant="overline">
                                                        {enrollmentQuery.data.enrollment ? t("enrollments.enrolled") : t("enrollments.unenrolled")}
                                                        {enrollmentQuery.data.enrollment &&
                                                            <IconButton
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => {
                                                                    confirm({
                                                                        title: t('common.are_you_sure'),
                                                                        description: t('common.unenroll_description_modal'),
                                                                        confirmationText: t('common.ok'),
                                                                        cancellationText: t('common.cancel'),
                                                                    })
                                                                        .then(() => {
                                                                            deleteEnrollmentMutation
                                                                                .mutateAsync(enrollment_id)
                                                                                .then(() => enqueueSnackbar(`user unenrolled successfully`, { variant: 'success', }))
                                                                                .catch(() => enqueueSnackbar(`Failed to unroll user `, { variant: 'error', }))
                                                                        })
                                                                        .catch(() => { })
                                                                }}>
                                                                <LogoutIcon fontSize="small" />
                                                            </IconButton>
                                                        }
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Box pr={1}><Typography variant="button" >{t("enrollments.subscription_date")}:</Typography></Box></td>
                                                <td><Typography variant="overline">{enrollmentQuery.data.subscription_date}</Typography></td>
                                            </tr>
                                            <tr>
                                                <td><Box pr={1}><Typography variant="button" >{t("enrollments.expiration_date")}:</Typography></Box></td>
                                                <td><Typography variant="overline">{enrollmentQuery.data.expiration_date}</Typography></td>
                                            </tr>
                                        </tbody>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
    )
}

function EnrollmentOrdersCard({ enrollment_id }) {
    const { t } = useTranslation()
    const enrollmentOrdersQuery = useGetEnrollmentOrders(enrollment_id)
    return (
        <Table
            title={t("orders.orders")}
            isLoading={enrollmentOrdersQuery.isFetching}
            options={{
                filtering: true,
                search: true,
                sorting: true,
            }}
            columns={[
                {
                    title: t('orders.id'),
                    field: "id",
                },
                {
                    title: t('orders.number_of_items'),
                    field: "number_of_items",
                },
                {
                    title: t('orders.status'),
                    field: "status",
                    lookup: {
                        RECEIVED: t("orders.Received"),
                        START: t("orders.Start"),
                        SCHEDULED_FOR_DELIVERY: t("orders.Schedule for Delivery"),
                        READY_FOR_DELIVERY: t("orders.Ready for Delivery"),
                        CANCELED: t("orders.Canceled"),
                        PROCESSING: t("orders.Processing"),
                        DELIVERED: t("orders.Delivered"),
                    }
                },
                {
                    title: t('orders.total_price'),
                    field: "total_price",
                },
                {
                    title: t('orders.enrollment_type'),
                    field: "enrollment_type",
                    old: t('enrollments.Old'),
                    current: t('enrollments.Current'),
                },
                {
                    title: t('orders.enrollment_id'),
                    field: "enrollment_id",
                },
                {
                    title: t('orders.created_datetime'),
                    field: "created_datetime",
                    type: 'datetime'
                },
            ]}
            data={enrollmentOrdersQuery.data}
        />
    )
}

export default function Enrollment() {
    const { t } = useTranslation()
    const { id } = useParams()
    return (
        <MainLayout
            title={t("enrollments.Enrollment_Info")}
            active="enrollments"
        >
            <Breadcrumbs
                items={[
                    { text: t('enrollments.Enrollments'), url: '/enrollments' },
                    { text: `#${id}` },
                    { text: t('breadcrumbs.View') },
                ]}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EnrollmentCost enrollment_id={id} />
                </Grid>
                <Grid item xs={12}>
                    <EnrollmentCard enrollment_id={id} />
                </Grid>
                <Grid item xs={12}>
                    <EnrollmentOrdersCard enrollment_id={id} />
                </Grid>
            </Grid>
        </MainLayout>
    )
}