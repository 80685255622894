import {
    useQuery,
    useQueryClient,
    useMutation,
} from "react-query"
import httpClient from "./httpClient"

export const useGetEnrollments = () => useQuery('enrollments', async () => {
    const response = await httpClient.get('/enrollments')
    return response.data
})

export const useGetEnrollment = (id) => useQuery(['enrollments', id], async () => {
    const response = await httpClient.get(`/enrollments/${id}`)
    return response.data
})

export const useDeleteEnrollment = () => {
    const queryClient = useQueryClient()
    return useMutation((id) => httpClient.delete(`/enrollments/${id}`), {
        onSuccess: () => { queryClient.invalidateQueries('enrollments') }
    })
}



export const useGetEnrollmentOrders = (id) => useQuery(['enrollments', id, 'orders'], async () => {
    const response = await httpClient.get(`/enrollments/${id}/orders`)
    return response.data
})

export const useGetEnrollmentOrdersCost = (id) => useQuery(['enrollments', id, 'orders', 'cost'], async () => {
    const response = await httpClient.get(`/enrollments/${id}/orders/cost`)
    return response.data
})