
import React from "react"
import {
    Routes,
    Route,
} from "react-router-dom"
import { MainLayout } from "./layouts"
import {
    LoginView,
    UsersView,
    UserView,
    EnrollmentsView,
    EnrollmentView,
    QRCodesView,
    QRCodeView,
    AddressesView,
    NotFoundView
} from "./views"
import { useAuthState } from 'react-firebase-hooks/auth'
import { ProtectedRoute } from "./components"
import { getAuth } from "firebase/auth"
import { useTranslation } from "react-i18next"
import moment from "moment"
function App() {
    const [user, loading] = useAuthState(getAuth())
    const { i18n } = useTranslation()
    moment.locale(i18n.language)
    return (!loading &&
        <Routes>
            <Route path="/" element={<ProtectedRoute isAllowed={!!user} />}>
                <Route
                    index element={<MainLayout
                        title='Home'
                        active="home"
                    >
                    </MainLayout>}
                />
                <Route path="users">
                    <Route index element={<UsersView />} />
                    <Route path=":id" element={<UserView />} />
                </Route>
                <Route path="enrollments">
                    <Route index element={<EnrollmentsView />} />
                    <Route path=":id" element={<EnrollmentView />} />
                </Route>
                <Route path="qr-codes">
                    <Route index element={<QRCodesView />} />
                    <Route path=":id" element={<QRCodeView />} />
                </Route>
                <Route path="addresses">
                    <Route index element={<AddressesView />} />
                </Route>
            </Route>
            <Route path="login" element={<ProtectedRoute isAllowed={!user} redirectPath="/" />}>
                <Route index element={<LoginView />} />
            </Route>
            <Route path="*" element={<NotFoundView/>} />
        </Routes>
    )
}

export default App
