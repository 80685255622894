import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { MainLayout } from "../../layouts"
import { Breadcrumbs } from "../../components"
import { useGetQRCode } from "../../api/qrCodes"
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Typography
} from "@mui/material"
import { Box } from "@mui/system"
import QRCodeImg from "react-qr-code"
import { useReactToPrint } from "react-to-print"
import moment from "moment"
import { useNavigate } from 'react-router-dom'
import { Visibility as VisibilityIcon } from '@mui/icons-material'
import { useAuthState } from "react-firebase-hooks/auth"
import { getAuth } from "firebase/auth"


function PrintComponent({ children, name }) {
    const componentRef = React.useRef(null)
    const { t } = useTranslation()
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current
    }, [])
    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `qr-code-${name}`,
        removeAfterPrint: true
    })
    return (
        <>
            <Button onClick={handlePrint}>{t("common.Print")}</Button>
            <div style={{ display: 'none' }}>
                <div ref={componentRef} >
                    {React.cloneElement(children, { ref: componentRef })}
                </div>
            </div>
        </>
    )
}

export default function QRCode() {
    const { id } = useParams()
    const { t } = useTranslation()
    const qrCodeQuery = useGetQRCode(id)
    const navigate = useNavigate()
    const [user] = useAuthState(getAuth())
    return (
        <MainLayout
            title={t("QRCodes.QR_Code")}
            active="qr-codes"
        >
            <Breadcrumbs
                items={[
                    { text: t("QRCodes.QR_Code"), url: '/qr-codes' },
                    { text: `#${id}` },
                    { text: t("breadcrumbs.View") },
                ]}
            />
            {
                qrCodeQuery.isLoading
                    ? <Skeleton variant="rectangular" width="100%" height={200} />
                    : qrCodeQuery.isError
                        ? "error"
                        :
                        <Paper>
                            <Box py={4} px={3}>
                                <Grid container spacing={3} direction="row-reverse">
                                    <Grid item xs={12} sm={12} md={6} textAlign='center'>
                                        <QRCodeImg value={`${process.env.REACT_APP_WW_REGISTER}/${user.tenantId}/${qrCodeQuery.data.id}`} />
                                        <div>
                                            <PrintComponent name={qrCodeQuery.data.id}>
                                                <Box p={5}>
                                                    <Typography gutterBottom variant="caption">#{qrCodeQuery.data.id}</Typography>
                                                    <Typography gutterBottom variant="h3" align="center">To use WishWash service </Typography>
                                                    <Typography gutterBottom variant="body1" >1. scan the QR code below </Typography>
                                                    <QRCodeImg value={`${process.env.REACT_APP_WW_REGISTER}/${user.tenantId}/${qrCodeQuery.data.id}`} />
                                                    <Typography gutterBottom variant="body1" >2. fill your data to create WishWash account, if you already have one navigate to login page</Typography>
                                                    <Typography gutterBottom variant="body1" >3. Download WishWash app and start use our services </Typography>
                                                    <Typography gutterBottom variant="caption">#{qrCodeQuery.data.id}</Typography>
                                                    <Divider />
                                                </Box>
                                            </PrintComponent>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="button" display="block" style={{ fontSize: '16px' }}>{t("QRCodes.QR_Code_Info")}</Typography>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td><Box pr={1}><Typography variant="button" >{t("QRCodes.reference_token")}:</Typography></Box></td>
                                                            <td><Typography variant="overline">{qrCodeQuery.data.id}</Typography></td>
                                                        </tr>
                                                        <tr>
                                                            <td><Box pr={1}><Typography variant="button" >{t("QRCodes.notes")}:</Typography></Box></td>
                                                            <td><Typography variant="overline">{qrCodeQuery.data.notes}</Typography></td>
                                                        </tr>
                                                        <tr>
                                                            <td><Box pr={1}><Typography variant="button" >{t("QRCodes.used")}:</Typography></Box></td>
                                                            <td><Typography variant="overline">{qrCodeQuery.data.used ? t('common.Yes') : t('common.No')}</Typography></td>
                                                        </tr>
                                                        <tr>
                                                            <td><Box pr={1}><Typography variant="button" >{t("QRCodes.token_expire_date")}:</Typography></Box></td>
                                                            <td><Typography variant="overline">{moment(qrCodeQuery.data.token_expire_date).format("YY-MM-DD HH:mm:ss Z")}</Typography></td>
                                                        </tr>
                                                        <tr>
                                                            <td><Box pr={1}><Typography variant="button" >{t("QRCodes.enrollment_expire_date")}:</Typography></Box></td>
                                                            <td><Typography variant="overline">{moment(qrCodeQuery.data.enrollment_expire_date).format("YY-MM-DD HH:mm:ss Z")}</Typography></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="button" display="inline" style={{ fontSize: '16px' }}>{t("QRCodes.User_Info")}</Typography>
                                                {!!qrCodeQuery.data.user &&
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => navigate(`/users/${qrCodeQuery.data.enrollment.user_id}`)}
                                                    >
                                                        <VisibilityIcon fontSize="small" />
                                                    </IconButton>
                                                }
                                                {qrCodeQuery.data.user
                                                    ? <table>
                                                        <tbody>
                                                            <tr>
                                                                <td><Box pr={1}><Typography variant="button" >{t("users.name")}:</Typography></Box></td>
                                                                <td><Typography variant="overline">{qrCodeQuery.data.user.name}</Typography></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Box pr={1}><Typography variant="button" >{t("users.dob")}:</Typography></Box></td>
                                                                <td><Typography variant="overline">{qrCodeQuery.data.user.dob}</Typography></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Box pr={1}><Typography variant="button" >{t("users.gender")}:</Typography></Box></td>
                                                                <td><Typography variant="overline">{qrCodeQuery.data.user.gender}</Typography></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    : <Typography display="block">no user found</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
            }

        </MainLayout>
    )
}