import i18n from "i18next"
import { getIdToken, getAuth } from "firebase/auth"

export default function authMiddleware(axiosInstance) {
    axiosInstance.interceptors.request.use(async function (config) {
        config.params = config.params ?? {}
        config.params.key = process.env.REACT_APP_API_KEY
        config.headers["Accept-Language"] = i18n?.language ?? "en"
        const user = getAuth().currentUser
        if (user) {
            try {
                let idToken = await getIdToken(user)
                config.headers['Authorization'] = `Bearer ${idToken}`
            }
            catch (error) {
            }
        }
        return config
    }, function (err) {
        return Promise.reject(err)
    })
    axiosInstance.interceptors.response.use(function (response) {
        return response
    }, function (err) {
        return Promise.reject(err)
    })
}