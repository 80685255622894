import React from "react";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

export default function MoreMenu({ items = [] }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }
    const handleOpen = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }
    return (
        <>
            <IconButton onClick={handleOpen}><MoreVertIcon /></IconButton>
            <Menu
                onClick={event => event.stopPropagation()}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {items.map((item, idx) => (
                    <MenuItem
                        disabled={item.disabled}
                        key={idx}
                        onClick={event => {
                            handleClose(event)
                            item.onClick()
                        }}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}