import { useTranslation } from "react-i18next"
import LanguageSelector from "./LanguageSelector"


export default function LanguageSelectorWrapper() {
    const { i18n } = useTranslation()
    const items = [{ value: 'en' }, { value: 'ar' }]
    const lang = items.some(item => item.value === i18n.language)
        ? i18n.language
        : 'en'
    return <LanguageSelector
        items={items}
        onClick={event => i18n.changeLanguage(event.target.value)}
        value={lang}
    />
}