import axios from "axios"
import authMiddleware from "./auth.middleware"

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
})
authMiddleware(httpClient)

export default httpClient
