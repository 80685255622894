import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { MainLayout } from "../../layouts"
import { Table } from "../../components"
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
    Add as AddIcon,
    CloseRounded as CloseRoundedIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material'
import {
    useGetAddresses,
    useDeleteAddress,
    useCreateAddress,
    useGetRegions
} from "../../api/addresses"
import {
    Button,
    Divider,
    Drawer,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography
} from "@mui/material"
import { Box } from "@mui/system"
import { useConfirm } from 'material-ui-confirm'

const validationSchema = yup.object().shape({
    'district': yup.string().required(),
    'door_number': yup.string().required(),
    'building': yup.string().required(),
    'note': yup.string().required(),
    'region': yup.string().required(),
    'street_name': yup.string().required(),
    'longitude': yup.string().required(),
    'latitude': yup.string().required(),

})

function CreateAddressDrawer({
    open,
    onClose
}) {
    const { t } = useTranslation()
    const createAddressMutation = useCreateAddress()
    const regionsQuery = useGetRegions()
    const { enqueueSnackbar } = useSnackbar()
    const formik = useFormik({
        initialValues: {
            'district': '',
            'door_number': '',
            'building': '',
            'note': '',
            'region': '',
            'street_name': '',
            'longitude': '1',
            'latitude': '1',
        },
        validationSchema,
        onSubmit: (values, actions) => {
            const payload = { ...values }
            payload['longitude'] = Number(payload['longitude'])
            payload['latitude'] = Number(payload['latitude'])
            createAddressMutation
                .mutateAsync(payload)
                .then((res) => {
                    enqueueSnackbar(`address added successfully`, { variant: 'success', })
                    handleClose()
                })
                .catch(() => enqueueSnackbar(`failed to add address`, { variant: 'error', }))
                .finally(() => actions.setSubmitting(false))
        }
    })
    const handleClose = () => {
        formik.resetForm()
        onClose()
    }
    return (
        <Drawer
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={open}
            onClose={handleClose}
            anchor='right'
            PaperProps={{
                style: {
                    width: "95vw",
                    maxWidth: "500px"
                }
            }}

        >
            <Box p={2} width="100%" pb={5}>
                <Grid container alignItems="center" justifyContent="space-between"  >
                    <Grid item>
                        <Typography variant='h6' fontWeight={600}>{t("Addresses.Create_Address")}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <Box pt={3}>
                    <Grid
                        component="form"
                        container
                        flexDirection="column"
                        justifyContent="space-between"
                        onSubmit={formik.handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="region"
                                name="region"
                                label={t("Addresses.region")}
                                value={formik.values.region}
                                onChange={(event) => {
                                    formik.handleChange(event)
                                    formik.setFieldValue('district', '')
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.region && Boolean(formik.errors.region)}
                                helperText={formik.touched.region && formik.errors.region}
                                select
                            >
                                {regionsQuery.data?.map(location => (
                                    <MenuItem key={location.region} value={location.region}>
                                        {location.region}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="district"
                                name="district"
                                label={t("Addresses.district")}
                                value={formik.values.district}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.district && Boolean(formik.errors.district)}
                                helperText={formik.touched.district && formik.errors.district}
                                disabled={!formik.values.region}
                                select
                            >
                                {regionsQuery.data
                                    ?.find(location => location.region === formik.values.region)
                                    ?.districts
                                    ?.map(district => (
                                        <MenuItem key={district} value={district}>
                                            {district}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="street_name"
                                name="street_name"
                                label={t("Addresses.street_name")}
                                value={formik.values.street_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.street_name && Boolean(formik.errors.street_name)}
                                helperText={formik.touched.street_name && formik.errors.street_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="building"
                                name="building"
                                label={t("Addresses.building")}
                                value={formik.values.building}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.building && Boolean(formik.errors.building)}
                                helperText={formik.touched.building && formik.errors.building}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="door_number"
                                name="door_number"
                                label={t("Addresses.door_number")}
                                value={formik.values.door_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.door_number && Boolean(formik.errors.door_number)}
                                helperText={formik.touched.door_number && formik.errors.door_number}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note"
                                name="note"
                                label={t("Addresses.note")}
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={() => formik.resetForm()}
                                    >
                                        {t("common.Reset")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        {t("common.Submit")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Drawer >
    )
}
export default function Addresses() {
    const { t } = useTranslation()
    const addressesQuery = useGetAddresses()
    const { enqueueSnackbar } = useSnackbar()
    const deleteAddressMutation = useDeleteAddress()
    const [addressDrawer, setAddressDrawer] = React.useState(false)
    const confirm = useConfirm()
    return (
        <MainLayout
            title={""}
            active="Addresses"
        >
            <Table
                title={t("Addresses.Addresses")}
                isLoading={addressesQuery.isFetching}
                options={{
                    filtering: true,
                    search: true
                }}
                columns={[
                    {
                        title: t("Addresses.region"),
                        field: 'region',
                    },
                    {
                        title: t("Addresses.district"),
                        field: 'district',
                    },
                    {
                        title: t("Addresses.building"),
                        field: 'building',
                    },
                    {
                        title: t("Addresses.door_number"),
                        field: 'door_number',
                    },
                    {
                        title: t("Addresses.street_name"),
                        field: 'street_name',
                    },
                    {
                        title: t("Addresses.longitude"),
                        field: 'longitude',
                    },
                    {
                        title: t("Addresses.latitude"),
                        field: 'latitude',
                    },
                    {
                        title: t("Addresses.note"),
                        field: 'note',
                    },
                ]}
                actions={[
                    {
                        icon: AddIcon,
                        tooltip: t('QRCodes.Create_Address'),
                        isFreeAction: true,
                        onClick: (event) => setAddressDrawer(true)
                    },
                    {
                        icon: DeleteIcon,
                        tooltip: t('QRCodes.Delete_Address'),
                        onClick: (event, rowData) => {
                            confirm({
                                title: t('common.are_you_sure'),
                                description: t('common.unenroll_description_modal'),
                                confirmationText: t('common.ok'),
                                cancellationText: t('common.cancel'),
                            })
                                .then(() => {
                                    deleteAddressMutation
                                        .mutateAsync(rowData.id)
                                        .then(() => enqueueSnackbar(`address deleted successfully`, { variant: 'success', }))
                                        .catch(() => enqueueSnackbar(`Failed to delete address `, { variant: 'error', }))
                                })
                                .catch(() => { })
                        }
                    }
                ]}
                data={addressesQuery.data}
            />
            <CreateAddressDrawer
                open={addressDrawer}
                onClose={() => setAddressDrawer(false)}
            />
        </MainLayout>
    )
}