import {
    useQuery,
    useQueryClient,
    useMutation,
} from "react-query"
import httpClient from "./httpClient"

export const useGetUsers = () => useQuery('users', async () => {
    const response = await httpClient.get('/users')
    return response.data
})

export const useGetUser = (id) => useQuery(['users', id], async () => {
    const response = await httpClient.get(`/users/${id}`)
    return response.data
})

export const useDeleteUser = () => {
    const queryClient = useQueryClient()
    return useMutation((id) => httpClient.delete(`/users/${id}`), {
        onSuccess: () => { queryClient.invalidateQueries('users') }
    })
}



export const useGetUserOrders = (id) => useQuery(['users', id, 'orders'], async () => {
    const response = await httpClient.get(`/users/${id}/orders`)
    return response.data
})

export const useGetUserEnrollments = (id) => useQuery(['users', id, 'enrollments'], async () => {
    const response = await httpClient.get(`/users/${id}/enrollments`)
    return response.data
})