import {
    useQuery,
    useQueryClient,
    useMutation,
} from "react-query"
import httpClient from "./httpClient"
import moment from 'moment'

export const useGetQRCodes = () => useQuery('qr-codes', async () => {
    const response = await httpClient.get('/qr-codes')
    return response.data
})

export const useGetQRCode = (id) => useQuery(['qr-codes', id], async () => {
    const response = await httpClient.get(`/qr-codes/${id}`)
    return response.data
})


export const useCreateQRCode = () => {
    const queryClient = useQueryClient()
    return useMutation(data => {
        const payload = { ...data }
        payload.token_expire_date = moment(payload.token_expire_date).locale('en').format()
        payload.enrollment_expire_date = moment(payload.enrollment_expire_date).locale('en').format()

        return httpClient.post('/qr-codes', payload)
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('qr-codes')
            }
        }
    )
}

