import {
    useQuery,
    useQueryClient,
    useMutation,
} from "react-query"
import httpClient from "./httpClient"

export const useGetAddresses = () => useQuery('addresses', async () => {
    const response = await httpClient.get('/addresses')
    return response.data
})

export const useCreateAddress = () => {
    const queryClient = useQueryClient()
    return useMutation(data => httpClient.post('/addresses', data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('addresses')
            }
        }
    )
}

export const useDeleteAddress = () => {
    const queryClient = useQueryClient()
    return useMutation(id => {
        return httpClient.delete(`/addresses/${id}`)
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('addresses')
            }
        }
    )
}


export const useGetRegions = () => useQuery('regions', async () => {
    const response = await httpClient.get('/addresses/regions')
    return response.data
})