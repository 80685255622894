
import React from 'react'
import MaterialTable from '@material-table/core'
import { useTranslation } from 'react-i18next'

const defaultOptions = {
    padding: 'dense',
    filtering: false,
    search: false,
    pageSize: 10,
    sorting: false,
    draggable: false,
}

export default function Table(props) {
    const { t } = useTranslation()
    return (
        <MaterialTable
            style={{ display: 'grid' }}
            {...props}
            options={{
                ...defaultOptions,
                ...props.options,
            }}
            localization={{
                pagination: {
                    labelDisplayedRows: `{from}-{to} ${t('table.of')} {count}`,
                    firstTooltip: t('table.firstPage'),
                    previousTooltip: t('table.previous'),
                    nextTooltip: t('table.next'),
                    lastTooltip: t('table.lastPage'),
                    labelRowsSelect: t('table.rows'),
                },
                toolbar: {
                    searchTooltip: t('table.search'),
                    searchPlaceholder: t('table.search'),
                    nRowsSelected: `{0} ${t('table.rowsSelected')}`
                },
                body: {
                    emptyDataSourceMessage: t('table.noRecords'),
                },
                header: {
                    actions: t('table.actions'),
                }
            }}
        />
    )
}
