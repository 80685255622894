import React from 'react'
import { Helmet } from 'react-helmet'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import {
    Menu as MenuIcon,
    Group as GroupIcon,
    EventNote as EventNoteIcon,
    BarChart as BarChartIcon,
    QrCode2 as QrCode2Icon,
    Logout as LogoutIcon,
    AddLocation as AddLocationIcon,

} from '@mui/icons-material'
import {
    Grid,
    IconButton
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import { useTranslation } from 'react-i18next'
import { LanguageSelectorWrapper } from '../../components/LanguageSelector'
import { logout } from '../../api/auth'
const drawerWidth = 240

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    // },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

const items = [
    {
        Icon: BarChartIcon,
        id: 'statistics',
        text: 'Statistics',
        url: '/'
    },
    {
        Icon: GroupIcon,
        id: 'renters',
        text: 'Renters',
        url: '/users'
    },
    {
        Icon: EventNoteIcon,
        id: 'enrollments',
        text: 'Enrollments',
        url: '/enrollments'
    },
    {
        Icon: QrCode2Icon,
        id: 'qr-codes',
        text: 'QR Codes',
        url: '/qr-codes'
    },
    {
        Icon: AddLocationIcon,
        id: 'addresses',
        text: 'Addresses',
        url: '/addresses'
    },

]

export default function Main({
    title = "TITLE",
    children,
    active = "",
    ...props
}) {
    const [open, setOpen] = React.useState(false)
    const { t } = useTranslation()
    const handleDrawerToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const navigate = useNavigate()
    return (
        <Box sx={{ display: 'flex' }}>
            <Helmet>
                <title> {t("common.Admin")} | {title || "WishWash"} </title>
            </Helmet>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                color="default"
            >
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs="auto">
                            <Typography variant="h6" noWrap component="div">
                                {t("navbar.admin_panel")}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" container spacing={1} >
                            <Grid item>
                                <LanguageSelectorWrapper />
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={() => logout()
                                        .then()
                                        .catch()
                                        .finally()
                                    }
                                >
                                    <LogoutIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader></DrawerHeader>
                <Divider />
                <List>
                    <ListItem
                        onClick={handleDrawerToggle}
                        button
                    >
                        <ListItemIcon><MenuIcon size="small" /></ListItemIcon>
                    </ListItem>
                </List>
                <List>
                    {items.map(({ id, Icon, text, url, disabled = false }, index) => (
                        <ListItem
                            onClick={() => { navigate(url) }}
                            button
                            key={id}
                            disabled={disabled}
                            selected={id === active}
                        >
                            <ListItemIcon><Icon /></ListItemIcon>
                            <ListItemText primary={t('sidebar.' + id)} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Grid container spacing={props.noPadding ? 0 : 3} direction='column'>
                    <Grid container item>
                        <Typography variant='h4' color='textPrimary'>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

// function Main(props) {
//     const [open, setOpen] = React.useState(false)
//     const Drawer = props.drawer
//     const SideMenu = props.sideMenu
//     const TitleButton = props.titleButton
//     return (
//         <React.Fragment>
//             <Helmet>
//                 <title> Admin | {props.title} </title>
//             </Helmet>
//             <AppBar
//                 drawer={props.drawerIcon
//                     && <Hidden mdUp>
//                         <IconButton
//                             aria-label='open-drawer'
//                             edge='start'
//                             onClick={() => setOpen(!open)}
//                             disableRipple
//                             className={classes.drawerToggleButton}
//                         >
//                             <Menu className={classes.drawerToggleIcon} color='inherit' />
//                         </IconButton>
//                     </Hidden>}
//             />
//             <Toolbar />
//             <div className={classes.root}>

//                 {props.drawer
//                     && <Drawer
//                         open={open}
//                         setOpen={setOpen}
//                         {...(props.drawerProps || {})}
//                     />}
//                 {props.sideMenu
//                     && <SideMenu
//                         {...(props.drawerProps || {})}
//                     />}
//                 <Grid container direction='column' justifyContent='space-between'>
//                     <Grid item>

//                         <main className={classes.content}>
//                             <Grid container spacing={props.noPadding ? 0 : 3} direction='column'>
//                                 <Grid container item justifyContent={props.centerTitle ? 'center' : 'space-between'}>
//                                     <Typography variant='h4' color='textPrimary'>
//                                         {props.title}
//                                     </Typography>
//                                     {props.titleButton && <TitleButton />}
//                                 </Grid>
//                                 <Grid item style={props.grid ? { display: 'grid' } : {}}>
//                                     {props.children}
//                                 </Grid>
//                             </Grid>
//                         </main>
//                     </Grid>

//                 </Grid>
//             </div>
//             <Footer />
//         </React.Fragment>
//     )
// }

// export default withWidth()(Main)