import React from 'react'
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { prefixer } from 'stylis'
import { useTranslation } from 'react-i18next'

export default function ThemeProvider({ children }) {
    const { i18n } = useTranslation()
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const cacheRtl = createCache({
        key: `mui-${direction}`,
        stylisPlugins: [...(direction === 'rtl' ? [prefixer, rtlPlugin] : [])],
    })
    document.dir = direction
    return (
        <MuiThemeProvider
            theme={
                responsiveFontSizes(
                    createTheme({
                        typography: {
                            "fontFamily": `"robbins", "cairo", "sans-serif"`,
                        },
                        direction,
                    }),
                )
            }>
            <CssBaseline />
            <CacheProvider value={cacheRtl}>{children}</CacheProvider>
        </MuiThemeProvider>
    )
}