import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { MainLayout } from "../../layouts"
import { Breadcrumbs, Table } from "../../components"
import {
    useGetUser,
    useDeleteUser,
    useGetUserOrders,
    useGetUserEnrollments
} from "../../api/users"
import { Logout as LogoutIcon } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import {
    Grid,
    IconButton,
    Link,
    Paper,
    Skeleton,
    Typography
} from "@mui/material"
import { Box } from "@mui/system"
import { useNavigate } from 'react-router-dom'
function UserCard({ user_id }) {
    const confirm = useConfirm()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const deleteUserMutation = useDeleteUser()
    const userQuery = useGetUser(user_id)
    return (
        userQuery.isLoading
            ? <Skeleton variant="rectangular" width="100%" height={200} />
            : userQuery.isError
                ? "error"
                : <Paper>
                    <Box py={3} px={2}>
                        <Grid container item xs={12} spacing={3} justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="button" display="block" style={{ fontSize: '16px' }}>{t("users.main_info")}</Typography>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><Box pr={1}><Typography variant="button" >{t("users.name")}:</Typography></Box></td>
                                                    <td><Typography variant="overline">{userQuery.data.name}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td><Box pr={1}><Typography variant="button" >{t("users.dob")}:</Typography></Box></td>
                                                    <td><Typography variant="overline">{userQuery.data.dob}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td><Box pr={1}><Typography variant="button" >{t("users.gender")}:</Typography></Box></td>
                                                    <td><Typography variant="overline">{userQuery.data.gender}</Typography></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="button" display="block" style={{ fontSize: '16px' }}>{t("users.enrollment")}</Typography>
                                        <tbody>
                                            <tr>
                                                <td><Box pr={1}><Typography variant="button" >{t("users.status")}:</Typography></Box></td>
                                                <td>
                                                    <Typography variant="overline">
                                                        {userQuery.data.enrollment ? t("users.enrolled") : t('users.unenrolled')}
                                                        {userQuery.data.enrollment &&
                                                            <IconButton
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => {
                                                                    confirm({
                                                                        title: t('common.are_you_sure'),
                                                                        description: t('common.unenroll_description_modal'),
                                                                        confirmationText: t('common.ok'),
                                                                        cancellationText: t('common.cancel'),
                                                                    })
                                                                        .then(() => {
                                                                            deleteUserMutation
                                                                                .mutateAsync(user_id)
                                                                                .then(() => enqueueSnackbar(`user unenrolled successfully`, { variant: 'success', }))
                                                                                .catch(() => enqueueSnackbar(`Failed to unroll user `, { variant: 'error', }))
                                                                        })
                                                                        .catch(() => { })
                                                                }}>
                                                                <LogoutIcon fontSize="small" />
                                                            </IconButton>
                                                        }
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
    )
}

function UserOrdersCard({ user_id }) {
    const { t } = useTranslation()
    const userOrdersQuery = useGetUserOrders(user_id)
    return (
        <Table
            title={t("orders.orders")}
            isLoading={userOrdersQuery.isFetching}
            options={{
                filtering: true,
                search: true,
                sorting: true,
            }}
            columns={[
                {
                    title: t("orders.id"),
                    field: 'id'
                },
                {
                    title: t("orders.number_of_items"),
                    field: 'number_of_items'
                },
                {
                    title: t("users.status"),
                    field: 'status',
                    lookup: {
                        RECEIVED: t("orders.Received"),
                        START: t("orders.Start"),
                        SCHEDULED_FOR_DELIVERY: t("orders.Schedule for Delivery"),
                        READY_FOR_DELIVERY: t("orders.Ready for Delivery"),
                        CANCELED: t("orders.Canceled"),
                        PROCESSING: t("orders.Processing"),
                        DELIVERED: t("orders.Delivered"),
                    }
                },
                {
                    title: t("orders.total_price"),
                    field: 'total_price'
                },
                {
                    title: t("orders.enrollment_type"),
                    field: 'enrollment_status',
                    lookup: {
                        old: t('enrollments.Old'),
                        current: t('enrollments.Current'),
                    },
                },
                {
                    title: t("orders.enrollment_id"),
                    field: 'enrollment.id'
                },
                {
                    title: t("orders.created_datetime"),
                    field: 'created_datetime', type: 'datetime'
                },
            ]}
            data={userOrdersQuery.data}
        />

    )

}

function UserEnrollmentsCard({ user_id }) {
    const { t } = useTranslation()
    const userEnrollmentsQuery = useGetUserEnrollments(user_id)
    const navigate = useNavigate()
    return (
        <Table
            title={t("enrollments.Enrollments")}
            isLoading={userEnrollmentsQuery.isFetching}
            options={{
                filtering: true,
                search: true,
                sorting: true,
            }}
            columns={[
                {
                    title: t("enrollments.id"),
                    field: 'id'
                },
                {
                    title: t("enrollments.subscription_date"),
                    field: 'subscription_date',
                    type: 'datetime'
                },
                {
                    title: t("enrollments.expiration_date"),
                    field: 'expiration_date',
                    type: 'datetime'
                },
                {
                    title: t('enrollments.QR_Code_Ref'),
                    field: 'qr_code',
                    render: rowData => rowData.qr_code
                        ? <Link onClick={event => {
                            event.stopPropagation()
                            navigate(`/qr-codes/${rowData.qr_code}`)
                        }}>
                            {rowData.qr_code}
                        </Link>
                        : ""
                },

            ]}
            data={userEnrollmentsQuery.data}
            onRowClick={(_, rowData) => navigate(`/enrollments/${rowData.id}`)}
        />

    )

}

export default function User() {
    const { t } = useTranslation()
    const { id } = useParams()
    return (
        <MainLayout
            title={t('users.Renter_Info')}
            active="renters"
        >
            <Breadcrumbs
                items={[
                    { text: t('users.Renters'), url: '/users' },
                    { text: `#${id}` },
                    { text: t('breadcrumbs.View') },
                ]}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UserCard user_id={id} />
                </Grid>
                <Grid item xs={12}>
                    <UserEnrollmentsCard user_id={id} />
                </Grid>
                <Grid item xs={12}>
                    <UserOrdersCard user_id={id} />
                </Grid>
            </Grid>
        </MainLayout>
    )
}