import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { MainLayout } from "../../layouts"
import { Table } from "../../components"
import { useGetEnrollments, useDeleteEnrollment } from "../../api/enrollments"
import {
    Check as CheckIcon,
    Clear as ClearIcon
} from '@mui/icons-material';
import { MoreMenu } from "../../components/Table"
import { useConfirm } from 'material-ui-confirm'
import { Link } from "@mui/material"

export default function Enrollments() {
    const { t } = useTranslation()
    const enrollmentsQuery = useGetEnrollments()
    const deleteEnrollmentMutation = useDeleteEnrollment()
    const navigate = useNavigate()
    const confirm = useConfirm()
    const { enqueueSnackbar } = useSnackbar()
    return (
        <MainLayout
            title={""}
            active="enrollments"
        >
            <Table
                title={t('enrollments.Enrollments')}
                isLoading={enrollmentsQuery.isFetching}
                options={{
                    filtering: true,
                    search: true
                }}
                columns={[
                    {
                        title: t('enrollments.id'),
                        field: 'id'
                    },
                    {
                        title: t('enrollments.subscription_date'),
                        field: 'subscription_date',
                        type: 'datetime'
                    },
                    {
                        title: t('enrollments.expiration_date'),
                        field: 'expiration_date',
                        type: 'datetime'
                    },
                    {
                        title: t('enrollments.QR_Code_Ref'),
                        field: 'qr_code',
                        render: rowData => rowData.qr_code
                            ? <Link onClick={event => {
                                event.stopPropagation()
                                navigate(`/qr-codes/${rowData.qr_code}`)
                            }}>
                                {rowData.qr_code}
                            </Link>
                            : ""
                    },
                    {
                        title: t('users.name'),
                        field: 'user.name',
                        render: rowData => rowData.user?.name
                            ? <Link onClick={event => {
                                event.stopPropagation()
                                navigate(`/users/${rowData.user_id}`)
                            }}>
                                {rowData.user.name}
                            </Link>
                            : ""
                    },
                    {
                        title: t('users.gender'),
                        field: 'user.gender',
                        lookup: {
                            Male: t('common.Male'),
                            Female: t('common.Female')
                        },
                    },
                    {
                        title: t('users.enrolled'),
                        field: 'enrollment',
                        lookup: {
                            [true]: t('common.Yes'),
                            [false]: t('common.No')
                        },
                        render: rowsData => rowsData.enrollment ? <CheckIcon color="success" /> : <ClearIcon color="error" />,
                    },
                    {
                        title: t('users.dob'),
                        field: 'user.dob'
                    },
                    {
                        title: "",
                        field: 'control',
                        width: "10px", filtering: false,
                        render: rowData => (
                            <MoreMenu
                                items={[
                                    {
                                        disabled: !rowData.enrollment,
                                        label: t('enrollments.unenroll'),
                                        onClick: () => {
                                            confirm({
                                                title: t('common.are_you_sure'),
                                                description: t('common.unenroll_description_modal'),
                                                confirmationText: t('common.ok'),
                                                cancellationText: t('common.cancel'),
                                            })
                                                .then(() => {
                                                    deleteEnrollmentMutation
                                                        .mutateAsync(rowData.id)
                                                        .then(() => enqueueSnackbar(`user unenrolled successfully`, { variant: 'success', }))
                                                        .catch(() => enqueueSnackbar(`Failed to unroll user `, { variant: 'error', }))
                                                })
                                                .catch(() => { })
                                        }
                                    },
                                ]}
                            />
                        )
                    }
                ]}
                data={enrollmentsQuery.data}
                onRowClick={(_, rowData) => navigate(`/enrollments/${rowData.id}`)}
            />
        </MainLayout>
    )
}